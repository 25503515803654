import { createAction, props } from '@ngrx/store';
import { Label } from '../../models/app.models';

export const savePersonalInformationClicked = createAction(
  '[My Profile Container] Save Changes Profile Clicked',
);
export const saveChangesProfileClickedWasSuccessful = createAction(
  '[My Profile Container] Customer Update Was Successful',
  props<{
    updateResponse: any;
  }>(),
);
export const changePasswordClicked = createAction(
  '[My Profile Container] Change Password Clicked',
);
export const newLabelClicked = createAction(
  '[My Profile Container] New Label Clicked',
);
export const saveAddressClicked = createAction(
  '[My Profile Container] Save Address Clicked',
);
export const editLabelClicked = createAction(
  '[My Profile Container] Edit Label Clicked',
  props<{ label: Label }>(),
);
export const changePasswordClickedWasSuccessful = createAction(
  '[My Profile Container] Change Password Was Successful',
  props<{
    updateResponse: any;
  }>(),
);
export const deleteLabelClicked = createAction(
  '[My Profile Container] Delete Label Clicked',
  props<{
    labelId: number;
  }>(),
);
export const deleteLabelAbortClicked = createAction(
  '[My Profile Container] Delete Label Abort Clicked',
);
export const deleteLabelSubmitClicked = createAction(
  '[My Profile Container] Delete Label Submit Clicked',
);
export const activateOrbitClicked = createAction(
  '[My Profile Container] Activate Orbit Clicked',
);
export const cancelOrbitClicked = createAction(
  '[My Profile Container] Cancel Orbit Clicked',
);
export const cancelOrbitSubmitClicked = createAction(
  '[My Profile Container] Cancel Orbit Submit Clicked',
);
export const cancelOrbitAbortClicked = createAction(
  '[My Profile Container] Cancel Orbit Abort Clicked',
);
export const reactivateOrbitClicked = createAction(
  '[My Profile Container] Reactivate Orbit Clicked',
);
export const reactivateOrbitSubmitClicked = createAction(
  '[My Profile Container] Reactivate Orbit Submit Clicked',
);
export const reactivateOrbitAbortClicked = createAction(
  '[My Profile Container] Reactivate Orbit Abort Clicked',
);

export const deleteProfileClicked = createAction(
  '[My Profile Container] Delete Profile Clicked',
);

export const deleteProfileSubmitClicked = createAction(
  '[My Profile Container] Delete Profile Abort Clicked',
);

export const deleteProfileAbortClicked = createAction(
  '[My Profile Container] Delete Profile Submit Clicked',
);
