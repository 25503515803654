import { createAction, props } from '@ngrx/store';
import { Event } from '../../models/app.models';

export const editEventClicked = createAction(
  '[My Events Container] Edit Event Clicked',
  props<{ event: Event }>(),
);
export const uploadEventClicked = createAction(
  '[My Events Container] Upload Event Clicked',
);
export const navigateToSubscriptionsClicked = createAction(
  '[My Events Container] Navigate To Subscriptions Clicked',
);
export const openOrbitClicked = createAction(
  '[My Events Container] Open Orbit Clicked',
  props<{ event: Event }>(),
);
