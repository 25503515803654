import { createAction, props } from '@ngrx/store';
import { Event, List, Lounge, Reservation } from '../../models/app.models';

export const orbitOpened = createAction(
  '[Orbit Container] Orbit opened',
  props<{ lists: List[] }>(),
);

export const createListClicked = createAction(
  '[Orbit Container] Create List Clicked',
  props<{ event: Event }>(),
);

export const openListClicked = createAction(
  '[Orbit Container] Open List Clicked',
  props<{ list: List }>(),
);

export const editListClicked = createAction(
  '[Orbit Container] Edit List Clicked',
  props<{ list: List }>(),
);

export const deleteListClicked = createAction(
  '[Orbit Container] Delete List Clicked',
  props<{ list: List }>(),
);

export const backClicked = createAction('[Orbit Container] Back Clicked');

export const openAllListsClicked = createAction(
  '[Orbit Container] All Lists Clicked',
  props<{ event: Event }>(),
);

export const openAllInviteesClicked = createAction(
  '[Orbit Container] All Invitees Clicked',
  props<{ event: Event }>(),
);

export const createLoungeClicked = createAction(
  '[Orbit Container] Create Lounge Clicked',
  props<{ event: Event }>(),
);

export const editLoungeClicked = createAction(
  '[Orbit Container] Edit Lounge Clicked',
  props<{ lounge: Lounge }>(),
);

export const openReservationRequestClicked = createAction(
  '[Orbit Container] Open Reservation Request Clicked',
  props<{ reservation: Reservation }>(),
);

export const assignReservationClicked = createAction(
  '[Orbit Container] Assign Reservation Clicked',
  props<{
    reservation: Reservation;
  }>(),
);

export const cancelReservationClicked = createAction(
  '[Orbit Container] Cancel Reservation Clicked',
  props<{
    reservation: Reservation;
  }>(),
);
