import { createAction, props } from '@ngrx/store';

export const StartWebsiteLogin = createAction(
  '[enterWithStart] Start Website Login',
  props<{
    loginResponse: any;
  }>(),
);

export const StartWebsiteLoginSkipped = createAction(
  '[enterWithStart] Start Website Login and skip 2fa and login',
  props<{
    loginResponse: any;
  }>(),
);
