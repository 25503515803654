import { createAction, props } from '@ngrx/store';
import {
  Customer,
  Event,
  Label,
  PasswordChange,
  Role,
  SubscriptionData,
} from '../../models/app.models';

export const languageUpdated = createAction('[App Effects] Updated Language');
export const twoFactorNotActive = createAction(
  '[App Effects] Two factor not active',
);
export const twoFactorActive = createAction('[App Effects] Two factor active');
export const twoFACodeGenerated = createAction(
  '[App Effects] Two factor code generated',
  props<{ qrCode: string }>(),
);
export const loginButtonClickedIsValid = createAction(
  '[App Effects] Login Button Clicked Is Valid',
  props<{
    email: string;
    password: string;
  }>(),
);
export const paymentStartedSuccessful = createAction(
  '[App Effects] Payment Started Successful',
  props<{
    paymentId: string;
  }>(),
);
export const paymentStartedUnsuccessful = createAction(
  '[App Effects] Payment Started Unsuccessful',
);
export const loginFormValidationError = createAction(
  '[App Effects] Login Form Validation Error',
);
export const twoAuthNotCorrect = createAction(
  '[App Effects] Two Auth Not Correct',
);
export const setAuthClean = createAction('[App Effects] Set auth clean');
export const twoAuthCorrect = createAction(
  '[App Effects] Two Auth Correct',
  props<{ loginResponse: any }>(),
);
export const twoFactorLoginComplete = createAction(
  '[App Effects] Two Factor Login Complete',
  props<{
    loginResponse: any;
  }>(),
);
export const loginWasSuccessful = createAction(
  '[App Effects] Login Was Successful',
  props<{ loginResponse: any }>(),
);
export const loginNotSuccessful = createAction(
  '[App Effects] Login Not Successful',
);
export const sessionWasFound = createAction(
  '[App Effects] Session Was Found',
  props<{ session: string }>(),
);
export const sessionNotFound = createAction('[App Effects] Session Not Found');
export const sessionLoginIsValid = createAction(
  '[App Effects] Session Login Is Valid',
  props<{
    loginResponse: any;
  }>(),
);
export const sessionLoginNotValid = createAction(
  '[App Effects] Session Login Not Valid',
);
export const loggedIn = createAction(
  '[App Effects] Logged In',
  props<{
    customerData: {
      internalLabel: Label[];
      events: Event[];
      subscription: SubscriptionData;
    };
  }>(),
);
export const reloadCustomerDataSuccess = createAction(
  '[App Effects] Reload Customer Data Success',
  props<{
    customerData: {
      internalLabel: Label[];
      events: Event[];
      subscription: SubscriptionData;
    };
  }>(),
);
export const savePersonalInformationClickedIsValid = createAction(
  '[App Effects] Save Changes Profile Clicked Is Valid',
  props<{
    customer: Customer;
  }>(),
);
export const saveAddressClickedIsValid = createAction(
  '[App Effects] Save Address Clicked Is Valid',
  props<{
    customer: Customer;
  }>(),
);
export const changePasswordClickedIsValid = createAction(
  '[App Effects] Change Password Clicked Is Valid',
  props<{
    customerId: number;
    password: PasswordChange;
  }>(),
);
export const profileFormValidationError = createAction(
  '[App Effects] Profile Form Validation Error',
);
export const changePasswordFormPasswordNotMatchingError = createAction(
  '[App Effects] The Passwords are not matching!',
);
export const changePasswordFormValidationError = createAction(
  '[App Effects] Change Password Form Validation Error',
);

export const finishedLabelFormSuccessful = createAction(
  '[App Effects] Finished Label Form Successful',
);
export const labelFormFailed = createAction('[App Effects] Label Form Failed');
export const alreadyLoggedIn = createAction('[App Effects] Already logged in');
export const customerDataObtained = createAction(
  '[App Effects] Customer Data Obtained',
  props<{
    customer: Customer;
    roles: Role[];
  }>(),
);
export const deleteLabelSuccessful = createAction(
  '[App Effects] Delete Label Successful',
);
export const deleteLabelFailed = createAction(
  '[App Effects] Delete Label Failed',
);
export const orbitCancelledSuccessfully = createAction(
  '[App Effects] Orbit Cancelled Successfully',
);
export const orbitCancelledUnsuccessfully = createAction(
  '[App Effects] Orbit Cancelled Unsuccessfully',
);
export const orbitReactivatedSuccessfully = createAction(
  '[App Effects] Orbit Reactivated Successfully',
);
export const orbitReactivatedUnsuccessfully = createAction(
  '[App Effects] Orbit Reactivated Unsuccessfully',
);
export const dataObtainedForOrbit = createAction(
  '[App Effects] Date for Orbit Obtained',
  props<{ event: Event }>(),
);
